exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kursy-js": () => import("./../../../src/pages/kursy.js" /* webpackChunkName: "component---src-pages-kursy-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-lekcja-probna-js": () => import("./../../../src/pages/lekcja-probna.js" /* webpackChunkName: "component---src-pages-lekcja-probna-js" */),
  "component---src-pages-opinie-js": () => import("./../../../src/pages/opinie.js" /* webpackChunkName: "component---src-pages-opinie-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-tlumaczenia-js": () => import("./../../../src/pages/tlumaczenia.js" /* webpackChunkName: "component---src-pages-tlumaczenia-js" */)
}

